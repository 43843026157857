import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/Layout'

import '../components/cookies.css'


export default ({ location }) => {

  return (
    <div>
      <SEO title="Kontakt" pathname={location.pathname} keywords="kontakt, Bielsko-Biała, szkoła językowa" />
      <Layout>
        <div className="cookies">
          <h1>Polityka prywatności</h1>
          <ul>
            <li>Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach cookies.</li>
            <li>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</li>
            <li>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.</li>
            <li>Pliki cookies wykorzystywane są w celu:</li>
            <ul>
              <li>dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika Serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;</li>
              <li>tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;</li>
            </ul>
            <li>W ramach Serwisu stosowany jest jeden rodzaj plików cookies: „stałe” (persistent cookies). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</li>
            <li>W ramach Serwisu stosowane są następujące rodzaje plików cookies:</li>
            <ul>
              <li>„wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu;</li>
              <li>„funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;</li>
              <li>„reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.</li>
            </ul>
            <li>W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).</li>
            <li>Operator Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.</li>
            <li>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być również przez współpracujących z operatorem Serwisu reklamodawców oraz partnerów.</li>
            <li>Więcej informacji na temat plików cookies można znaleźć w sekcji „Pomoc” w menu popularnych przeglądarek internetowej.</li>
          </ul>
          <div>
            Poniżej przedstawiamy informację, jak zmienić ustawienia dotyczące cookies w najpopularniejszych przeglądarkach internetowych:
          </div>
          <ul>
            <li className="link">Jeżeli używasz przeglądarki Google Chrome, to ustawienia dotyczące cookies możesz zmienić <Link to="http://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647" target='blank'>tutaj</Link>.</li>
            <li className="link">Jeżeli używasz przeglądarki Microsoft Internet Explorer, to ustawienia dotyczące cookies możesz zmienić <Link to="http://windows.microsoft.com/pl-pl/windows7/how-to-manage-cookies-in-internet-explorer-9" target='blank'>tutaj</Link>.</li>
            <li className="link">Jeżeli używasz przeglądarki Mozilla Firefox, to ustawienia dotyczące cookies możesz zmienić <Link to="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek" target='blank'>tutaj</Link>.</li>
            <li className="link">Jeżeli używasz przeglądarki Opera, to ustawienia dotyczące cookies możesz zmienić <Link to="http://help.opera.com/Linux/12.10/pl/cookies.html" target='blank'>tutaj</Link>.</li>
            <li className="link">Jeżeli używasz przeglądarki Safari, to ustawienia dotyczące cookies możesz zmienić <Link to="http://support.apple.com/kb/PH5042?viewlocale=pl_PL&locale=pl_PL" target='blank'>tutaj</Link>.</li>
          </ul>
        </div>
      </Layout>
    </div>
  )
}
